
/* eslint-disable */
import { defineComponent, computed, onMounted } from "vue";
import {
  DxFileManager,
  DxPermissions,
  DxToolbar,
  DxContextMenu,
  DxItem,
  DxFileSelectionItem,
  DxItemView,
  DxDetails,
  DxColumn
} from "devextreme-vue/file-manager";
import { DxPopup } from "devextreme-vue/popup";
import RemoteFileSystemProvider from "devextreme/file_management/remote_provider";
import "devextreme/dist/css/dx.light.css";
import "devextreme-intl";
import twMessages from "@/locales/devextreme/zh-tw.json";
import { locale, loadMessages } from "devextreme/localization";

const arg = JSON.stringify({
  pathInfo: [{ key: "resources", name: "resources" }]
});
const remoteProvider = new RemoteFileSystemProvider({
  endpointUrl: `${process.env.VUE_APP_BACKEND_URL}/api/FileManager/FileManagement`
  // endpointUrl: `${process.env.VUE_APP_BACKEND_URL}/api/FileManager/FileManagement?command=0&arguments=${arg}`
});

export default defineComponent({
  name: "FileManager",
  components: {
    DxFileManager,
    DxPermissions,
    DxToolbar,
    DxContextMenu,
    DxItem,
    DxFileSelectionItem,
    DxItemView,
    DxDetails,
    DxColumn,
    DxPopup
  },
  mounted() {},
  data() {
    loadMessages(twMessages);
    locale("zh-tw");
    return {
      remoteProvider,
      popupVisible: false,
      imageItemToDisplay: {},
      fileOptions: {
        text: "Insert Image",
        icon: "plus",
        onClick: this.handleCustomButtonClick
      }
    };
  },
  methods: {
    handleCustomButtonClick: function(e: any) {
      var fileManager = e.component;
      // console.log("CLICK!", fileManager);
      //       var selectedFiles = fileManager.getSelectedItems();
      // // var selectedFiles = e.component.getSelectedItems();
      // // 遍歷選定的文件
      // for (var i = 0; i < selectedFiles.length; i++) {
      //   // 顯示文件名和大小
      //   console.log(selectedFiles[i].name + ' - ' + selectedFiles[i].size);
      // }
    },
    fileManager_onToolbarItemClick(e: any) {
      // console.log(e);
      if (e.itemData.name === "insertImage") {
        // console.log(e.component.getSelectedItems())
        var imgArray = e.component.getSelectedItems();
        this.$emit("insert", imgArray);
      }
      // if (extension) {
      //   updated = this.createFile(extension, fileSystemItem);
      // } else if (category !== undefined) {
      //   updated = this.updateCategory(category, fileSystemItem, viewArea);
      // }

      // if (updated) {
      //   this.$refs.fileManager.instance.refresh(
      // }
    },
    displayImagePopup(e: any) {
      this.imageItemToDisplay = {
        name: e.file.name,
        url: e.file.dataItem.url
      };
      this.popupVisible = true;
    },
    customizeIcon(fileSystemItem: any) {
      console.log("file:", fileSystemItem);
      if (fileSystemItem.isDirectory) {
        return "images/thumbnails/folder.svg";
      }

      const fileExtension = fileSystemItem.getFileExtension();
      switch (fileExtension) {
        case ".txt":
          return "images/thumbnails/doc-txt.svg";
        case ".rtf":
          return "images/thumbnails/doc-rtf.svg";
        case ".xml":
          return "images/thumbnails/doc-xml.svg";
        default:
          return "images/thumbnails/doc-txt.svg";
      }
    }
  }
});
